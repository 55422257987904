<template>
  <div class="content-wrapper">
    <bo-page-title></bo-page-title>
    <div class="content pt-0">
				<!-- Dashboard content -->
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Informasi Bucket</h5>
						<div class="row no-gutters justify-content-center">
							<div v-for="(v,k) in informasiBucket" :key="k" class="col-md-6 col-lg-3 bucket-info">
								<div class="bucket-info-head">
									<div class="bi-border bg-blue-custom"></div>
									<div class="bi-count bg-blue-custom"><span>{{v.total}}</span></div>
									<div class="bi-title">{{v.mbf_name}}</div>
								</div>
								<div class="bucket-info-body c-pointer" @click="openModal(v.mbf_id,v.mbf_name)">
									<strong>Rp. {{parseInt(v.bucketAmount||'0').toLocaleString('id-ID')}}</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="card">
					<div class="card-header">
						<h5 class="card-title">Target Pencairan Bulanan</h5>
					</div>
					<div class="card-body">
						<div class="chart-container">
							<BarChart :chartData="chartData"
								:chartOptions="chartOptions"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6">
				<div class="card">
					<div class="card-header">
						<h5 class="card-title">Total Drop Calon Debitur Potensial</h5>
					</div>
					<div class="card-body">
						<div v-if="(dataChartDrop.labels||[]).length" class="chart-container">
							<!--
							<PieChart :chartData="dataChartDrop"
								:chartOptions="pluggins"
							/>
							<div class="mt-4">
								<p v-for="(v,k) in (dataChartDrop.dataList||[])" :key="k" :style="'color:'+v.color">
									{{v.label}}:<b>{{v.totalAmount}}</b>
								</p>
							</div>
							-->
							<apexchart type="pie" :options="chartOptionsX" :series="series"></apexchart>
						</div>
						<div v-else>
							<p>Tidak ada data</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="card-header row">
				<h5 class="card-title font-weight-semibold col">Target Pencairan</h5>
				<router-link :to="{name: 'BoLoan'}" class="btn btn-outline alpha-purple text-purple-800">Lihat Detail</router-link>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="float-right mr-3">
						<span class="ml-5 text-success">Hijau : <b>0x Revisi pencairan</b></span>
						<span class="ml-5 text-secondary">Abu-Abu : <b>1x Revisi pencairan</b></span>
						<span class="ml-5 text-danger">Merah : <b>Lebih dari 1x Revisi pencairan</b></span>							
					</div>
				</div>
			</div>
			<div class="table-responsive">
				<table class="table table-striped">
					<thead>
						<tr>
							<th>No.</th>
							<th>Nama</th>
							<th>Bucket Saat Ini</th>
							<th>Durasi Bucket</th>
							<th>ID Officer</th>
							<th>Jml. Pembiayaan</th>
							<th>Revisi Pencairan</th>
							<th>Notes</th>
						</tr>
					</thead>
					<tbody v-for="(v,k) in dataTarget" :key="k">
						<tr>
							<td colspan="8" class="table-primary"><span class="font-weight-semibold">Department: {{v.department_name}}</span></td>
						</tr>
						<template v-if="(v.loanData.data||[]).length">
							<tr v-for="(v2,k2) in v.loanData.data" :key="k2">
								<td>
					            	{{(v.loanData.per_page*(v.loanData.current_page-1))+k2+1}}
								</td>
								<td>{{ v2.debitur_name }}</td>
								<td>
									<span>
										<b-badge v-if="v2.ld_completed_status=='N'" variant="warning">Incomplete</b-badge>
										<b-badge v-if="v2.ld_drop_status=='Y'" variant="danger">Drop ({{ v2.ld_drop_type }})</b-badge>
										<b-badge v-if="v2.ld_finish_status=='Y'" variant="success">Investment</b-badge>

										<div v-if="v2.ld_completed_status=='Y' && v2.ld_drop_status!='Y' && v2.ld_finish_status!='Y'" class="mt-1">
											<div v-for="(v3,k3) in v2.buckets" :key="k3" class="mb-1">
												<b-badge 
													:variant="v3.color"
													v-b-tooltip.hover 
													v-if="( 
													v2.buckets.length==1 || 
													( v2.buckets.length>1 && v3.finish_status=='N' ) 
													)"
													class="mr-1"
												>{{ v3.name }}</b-badge>
											</div>
										</div>
										<template v-else>
											<div class="mt-1" v-if="v2.ld_finish_status!=='Y'&& v2.ld_drop_status!=='Y'">
												<b-badge variant="info">Initiation (Hot Prospect)</b-badge>
											</div>  
										</template>
									</span>
								</td>
								<td>
									<template v-if="v2.ld_completed_status=='Y'" >
									<div class="result_tab">
										<h4>Dimulai pada</h4>
										<p>{{ v2.ld_bucket_join_since | moment('DD MMM YYYY HH:MM') }}</p>
									</div>
									<span class="font-weight-semibold text-blue-700">
										({{ v2.ld_bucket_join_since | moment("from", "now", true) }} yang lalu)
									</span>
									</template>
									<template v-else>
									<div class="result_tab">
										<h4>Dimulai pada</h4>
										<p>{{ v2.ld_created_date | moment('DD MMM YYYY HH:MM') }}</p>
									</div>
									<span class="font-weight-semibold text-blue-700">
										({{ v2.ld_created_date | moment("from", "now", true) }} yang lalu)
									</span>
									</template>
								</td>
								<td>{{v2.nama_id_officer}}</td>
								<td><span class="font-weight-semibold">{{ parseInt(v2.ld_loan_amount||'0').toLocaleString('id-ID') }}</span></td>
								<td v-if="v2.ld_changed_est_disbursed_date>1" class="text-danger">{{ v2.ld_est_disbursed_date | moment("DD MMMM YYYY")}}</td>
								<td v-else-if="v2.ld_changed_est_disbursed_date==1" class="text-secondary">{{ v2.ld_est_disbursed_date | moment("DD MMMM YYYY")}}</td>
								<td v-else class="text-success">{{ v2.ld_est_disbursed_date | moment("DD MMMM YYYY")}}</td>
								<td>{{ v2.reason_drop || "-"}}</td>
							</tr>	
							<tr v-if="(v.loanData.data||[]).length&&(v.loanData.total)>10">
								<td colspan="12">
									<b-pagination
										class="mb-0"
										v-model="pageNo"
										:per-page="v.loanData.per_page"
										:total-rows="v.loanData.total"
									/>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="8">Tidak ada data</td>
							</tr>	
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<div class="card">
			<div class="card-header row">
				<h5 class="card-title font-weight-semibold col">Potential Prospects</h5>
				<router-link :to="{name: 'BoDebiturPerusahaan'}" class="btn btn-outline alpha-purple text-purple-800">Lihat Detail</router-link>
			</div>
			<ul class="nav nav-tabs nav-tabs-highlight nav-justified">
				<li class="nav-item"><a href="javascript:;" @click="tabIndex = 0" :class="tabIndex == 0 ? 'nav-link active' : 'nav-link'" data-toggle="tab">
				<span class="badge bg-success mr-2" v-if="totalHot">{{totalHot}}</span>  Hot Prospect</a></li>
				<li class="nav-item"><a href="javascript:;" @click="tabIndex = 1" :class="tabIndex == 1 ? 'nav-link active' : 'nav-link'" class="nav-link"
						data-toggle="tab">
				<span class="badge badge-info mr-2" v-if="totalWarm">{{totalWarm}}</span> Warm Prospect</a></li>
			</ul>
			<div class="tab-content">
				<div :class="tabIndex == 0 ? 'tab-pane fade show active' : 'tab-pane fade'" id="badge-tab1">
					<div class="table-responsive">
						<table class="table table-striped">
							<thead>
								<tr>
									<th>#</th>
									<th>Nama</th>
									<th>Tgl. Ditambahkan</th>
									<th>ID Officer</th>
									<th>Est. Pembiayaan (IDR)</th>
									<th>Notes</th>
								</tr>
							</thead>
							<tbody v-if="(hotProspect||[]).length">
								<tr v-for="(v,k) in hotProspect" :key="k">
									<td>{{k+1}}</td>
									<td>
										<div class="wrap_content_tb">
											<h3>{{v.debitur_name}}</h3>
										</div>
									</td>
									<td>
										<div class="wrap_content_tb">
											<h3>{{v.ld_created_date | moment("DD MMMM YYYY, HH:mm")}} WIB</h3>
											<span class="text-info">{{momentDate(v.ld_created_date)}} di Prospek</span>
										</div>
									</td>
									<td>{{v.nama_id_officer}}</td>
									<td>
										<span class="font-weight-semibold">Rp. 
										{{parseInt(v.ld_loan_amount||'0').toLocaleString('id-ID')||"-"}}</span>
									</td>
									<td>{{v.dp_kelengkapan_data || "-"}}</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="6" class="text-center">Tidak ada Hot Prospect</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div :class="tabIndex == 1 ? 'tab-pane fade show active' : 'tab-pane fade'" id="badge-tab2">
					<div class="table-responsive">
						<table class="table table-striped">
							<thead>
								<tr>
									<th>#</th>
									<th>Nama</th>
									<th>Tgl. Ditambahkan</th>
									<th>ID Officer</th>
									<th>Notes</th>
								</tr>
							</thead>
							<tbody v-if="(warmProspect||[]).length">
								<tr v-for="(v,k) in warmProspect" :key="k">
									<td>{{k+1}}</td>
									<td>
										<div class="wrap_content_tb">
											<h3>{{v.nama_bu}}</h3>
										</div>
									</td>
									<td>
										<div class="wrap_content_tb">
											<h3>{{v.created_date | moment("DD MMMM YYYY, HH:mm")}} WIB</h3>
											<span class="text-info">{{momentDate(v.created_date)}} of Prospect</span>
										</div>
									</td>
									<td>{{v.id_officer_name}}</td>
									<td>{{v.catatan || "-"}}</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="5">Tidak ada Warm Prospect</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="card-header row">
				<h5 class="card-title font-weight-semibold col">Drop Calon Debitur Potensial</h5>
				<router-link :to="{name: 'BoLoan'}" class="btn btn-outline alpha-purple text-purple-800">Lihat Detail</router-link>
			</div>
			<div class="table-responsive">
				<table class="table table-striped">
					<thead>
						<tr>
							<th>No.</th>
							<th>Nama</th>
							<th>ID Officer</th>
							<th>Est. Pembiayaan (IDR)</th>
							<th>Alasan Drop</th>
							<th>Tgl. Drop</th>
							<th>Notes</th>
						</tr>
					</thead>
					<tbody v-if="(listDropDebitur||[]).length">
						<tr v-for="(v,k) in (listDropDebitur||[])" :key="k">
							<td>{{k+1}}</td>
							<td>{{v.debitur_name}}</td>
							<td>{{v.nama_id_officer}}</td>
							<td><span class="font-weight-semibold">{{ parseInt(v.ld_loan_amount||'0').toLocaleString('id-ID') }}</span></td>
							<td>{{v.reason_drop}}</td>
							<td>{{v.ld_drop_date | moment("DD MMMM YYYY, HH:mm")}}</td>
							<td>{{v.ld_drop_reason}}</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr>
							<td colspan="7">Tidak ada data drop</td>
						</tr>								
					</tbody>
				</table>
			</div>
		</div>
	</div>
	 
    <b-modal 
        v-model="modalOpen"
        :title="'Informasi Bucket '+modalTitle"
        size="lg"
        hide-footer
        body-class="p-0"
      >
	  <b-card-body class="p-0 mt-4">
        <b-table
          :fields="fields"
          :items="dataList.data||[]"
          :per-page="perPage"
          :primary-key="'ld_id'"
          :busy="!dataList.data"
          responsive
          show-empty
          striped
        >
          <template #empty>
            <div class="text-center">
              <h4 align="center"><span v-if="filter.level || filter.search">Hasil pencarian tidak ditemukan</span>
              <span v-else>Belum ada data Fasilitas yang terdaftar</span></h4>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(number)="v">
            {{(data.per_page*(data.current_page-1))+v.index+1}}
          </template>

          <template #cell(ld_created_date)="data">
            {{ data.value | moment('DD MMM YYYY') }}
          </template>
          
          <template #cell(ld_source)="data">
            {{ data.value=='MANUAL'?'Internal':'Customer Portal' }}
          </template>

          <template #cell(ld_loan_amount)="data">
            <div class="wrap_content_tb">
              <h3 v-if="data.item.ld_disbursed_amount">
                <strong>{{ (data.item.ld_disbursed_amount||0).format() }}</strong>
              </h3>
              <h3 v-else>
                <strong>{{ (data.value||0).format() }}</strong> <small>est.</small>
              </h3>
              <p>{{ data.item.loan_type_name }}</p>
            </div>
          </template>

          <template #cell(ld_tenor)="data">
            <span class="font-weight-semibold">{{ data.value||0 }} Bulan</span>
          </template>

          <template #cell(ld_bucket_join_since)="data">
            <template v-if="data.item.ld_completed_status=='Y'" >
              <div class="result_tab">
                <h4>Dimulai pada</h4>
                <p>{{ data.value | moment('DD MMM YYYY HH:mm') }}</p>
              </div>
              <span class="font-weight-semibold text-blue-700">
                ({{ data.value | moment("from", "now", true) }} yang lalu)
              </span>
            </template>
            <template v-else>
              <div class="result_tab">
                <h4>Dimulai pada</h4>
                <p>{{ data.item.ld_created_date | moment('DD MMM YYYY HH:mm') }}</p>
              </div>
              <span class="font-weight-semibold text-blue-700">
                ({{ data.item.ld_created_date | moment("from", "now", true) }} yang lalu)
              </span>
            </template>
          </template>

          <template #cell(buckets) = "data">
			<span>
				<b-badge v-if="data.item.ld_completed_status=='N'" variant="warning">Incomplete</b-badge>
				<b-badge v-if="data.item.ld_drop_status=='Y'" variant="danger">Drop ({{ data.item.ld_drop_type }})</b-badge>
				<b-badge v-if="data.item.ld_finish_status=='Y'" variant="success">Investment</b-badge>

				<div v-if="data.item.ld_completed_status=='Y' && data.item.ld_drop_status!='Y' && data.item.ld_finish_status!='Y'" class="mt-1">
					<div v-for="(v3,k3) in data.item.buckets" :key="k3" class="mb-1">
						<b-badge 
							:variant="v3.color"
							v-b-tooltip.hover 
							v-if="( 
							data.item.buckets.length==1 || 
							( data.item.buckets.length>1 && v3.finish_status=='N' ) 
							)"
							class="mr-1"
						>{{ v3.name }}</b-badge>
					</div>
				</div>
				<template v-else>
					<div class="mt-1" v-if="data.item.ld_finish_status!=='Y'&& data.item.ld_drop_status!=='Y'">
						<b-badge variant="info">Initiation (Hot Prospect)</b-badge>
					</div>  
				</template>
			</span>
		  	<!--
            <Status :row="data.item"/>
			-->
		  </template>
        </b-table>

		<div class="row">
			<div class="col-12 text-center p-3">
				<router-link
				:to="{name: 'BoLoan'}"
				>
					Lihat Detail
				</router-link>
			</div>
		</div>
      </b-card-body>
	</b-modal>
  </div>
</template>
<script>
import GlobalVue from '../../libs/Global.vue'
import BarChart from '@/components/chart/BarChart'
const moment = require('moment')
import Gen from '@/libs/Gen.js'
import _ from 'lodash'

export default {
 	extends: GlobalVue,
	components: {
        BarChart
	},
	data() {
    	return {
			informasiBucket: [],
			chartData:{},
			chartOptions:{
				responsive: false,
				maintainAspectRatios: false,
			},
			fields: [
				{
					key: 'number',
					label: '#',
				},
				{
					key: 'ld_created_date',
					label: 'Tanggal Pengajuan',
				},
				{
					key: 'debitur_name',
					label: 'Nama Debitur',
				},
				{
					key: 'nama_id_officer',
					label: 'Nama PIC',
				},
				{
					key: 'ld_loan_amount',
					label: 'Jumlah Pembiayaan (IDR)',
				},
				{
					key: 'ld_tenor',
					label: 'Tenor',
				},
				{
					key: 'buckets',
					label: 'Bucket Saat Ini',
				},
				{
					key: 'ld_bucket_join_since',
					label: 'Durasi Bucket',
				}
			],
			self:this,
			dataList: [],
			hotProspect: [],
			warmProspect: [],
			dataTarget:[],
			listDropDebitur: [],
			totalHot: 0,
			totalWarm: 0,
		    tabIndex: 0,
			dataChartDrop: {},
			chartOptionsX: {
				chart: {
					type: 'pie',
				},
				colors:[],
				labels: [],
				legend: {
					position: 'top'
				},
				responsive: [{
					legend: {
						position: 'top'
					}
				}],
				tooltip: {}
			},
			series: [],
			modalOpen: false,
			modalTitle: ""
		}
	},
	methods: {
		openHandler(pdfApp) {
          window._pdfApp = pdfApp
        },
		momentDate(dates){
			let formatted = moment(dates).format("YYYYMMDDHHmm")
			let newDates = moment(formatted, "YYYYMMDDHHmm").fromNow()
			return newDates
		},
		openModal(id,name){
			this.modalTitle = name
			this.modalOpen = true,
			this.loadingOverlay=true
			Gen.apiRest(
				"/get/"+this.modulePage+"/"+"list-modal",
				{params:{mbf_id : id}}
			).then(res=>{
				_.forEach(res.data, (v,k)=>{
				this.$set(this, k, v)
				})
				this.modalOpen = true
				this.loadingOverlay=false
				this.dataList = res.data.data
			})
		},
		apiGet(params = {}){
			if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
			this.data.data = false
			let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
			if(params.slugs) slugs = params.slugs
			Gen.apiRest(
				"/get/"+this.modulePage+
				slugs, 
				{
				params: Object.assign({}, this.apiParams, params.query||{})
				}
			).then(res=>{
				this.loadingOverlay = false
				_.forEach(res.data, (v,k)=>{
				this.$set(this, k, v)
				})

				this.series = this.dataChartDrop.amountDrop
				this.chartOptionsX.colors = this.dataChartDrop.backroundColor
				this.chartOptionsX.labels = this.dataChartDrop.labels
				var dataList = this.dataChartDrop.dataList
				this.chartOptionsX.tooltip = {
					y: {
						formatter: function(value,index) {
							return value + ", Nilai Total : Rp." + dataList[index.dataPointIndex].totalAmount.toLocaleString('id-ID')
						}
					}
				}
			}).catch(()=>{
				this.loadingOverlay = false
			})
		},
	},
	mounted(){
		this.apiGet()
		if(this.$route.params.pageSlug){
		this.validateModuleRoleCrud()
		}
	},
	watch:{
		$route(){
			this.apiGet()
		},
		'$route.params.pageSlug':function(){
			this.$set(this,'filter',{})
		}
  	}
}

</script>